import { accountTypes } from '../../../util/accountTypes';
import { profileSteps } from '../profileSteps';
import { getIsConsultant } from '../../../util/user';

export const calculatePercentages = currentUser => {
  const isConsultant = getIsConsultant(currentUser);

  var calculatedProgress = isConsultant
    ? [
      {
        step: profileSteps.profileDetails,
        progress: 0,
      },
      {
        step: profileSteps.workExperience,
        progress: 0,
      },
      {
        step: profileSteps.references,
        progress: 0,
      },
      {
        step: profileSteps.formalEducation,
        progress: 0,
      },
      {
        step: profileSteps.certifications,
        progress: 0,
      },
      {
        step: profileSteps.skills,
        progress: 0,
      },
      {
        step: profileSteps.languages,
        progress: 0,
      },
      {
        step: profileSteps.hourlyRate,
        progress: 0,
      },
      // {
      //   step: profileSteps.paymentMethod,
      //   progress: 0,
      // },
    ]
    : [
      {
        step: profileSteps.profileDetails,
        progress: 0,
      },
      {
        step: profileSteps.companyProfile,
        progress: 0,
      },
      {
        step: profileSteps.paymentMethod,
        progress: 0,
      },
    ];

  const updatePercentageForConsultantUserProfile = () => {
    //profile details
    var percentage = 0;
    const percentageStep = 11.1;
    if (currentUser.attributes.profile.firstName) {
      percentage += percentageStep;
    }
    if (currentUser.attributes.profile.lastName) {
      percentage += percentageStep;
    }

    if (currentUser.attributes.profile.publicData.headline) {
      percentage += percentageStep;
    }

    // Profile image is optional
    // if (currentUser.profileImage) {
    //   percentage += percentageStep;
    // }

    //profile address
    if (currentUser.attributes.profile.protectedData.address?.city) {
      percentage += percentageStep;
    }

    if (currentUser.attributes.profile.protectedData.address?.country) {
      percentage += percentageStep;
    }

    if (currentUser.attributes.profile.protectedData.address?.state) {
      percentage += percentageStep;
    }

    if (currentUser.attributes.profile.protectedData.address?.streetName) {
      percentage += percentageStep;
    }

    if (currentUser.attributes.profile.protectedData.address?.suburb) {
      percentage += percentageStep;
    }

    if (currentUser.attributes.profile.protectedData.address?.zipCode) {
      percentage += percentageStep;
    }

    calculatedProgress.find(v => v.step === profileSteps.profileDetails).progress = Math.round(percentage);
  };

  const updatePercentageForWorkExperience = () => {
    if (
      currentUser.attributes.profile.publicData.workExperience &&
      currentUser.attributes.profile.publicData.workExperience.length > 0
    ) {
      calculatedProgress.find(v => v.step === profileSteps.workExperience).progress = 100;
    }
  };

  const updatePercentageForReferences = () => {
    if (
      currentUser.attributes.profile.protectedData.references &&
      currentUser.attributes.profile.protectedData.references.length > 0
    ) {
      // const noOfReferences = currentUser.attributes.profile.protectedData.references.length;
      // var percentage = 0;
      // if (noOfReferences === 1) {
      //   percentage = 100;
      // } else if (noOfReferences === 2) {
      //   percentage = 66;
      // } else if (noOfReferences >= 3) {
      //   percentage = 100;
      // }

      calculatedProgress.find(v => v.step === profileSteps.references).progress = 100;
    }
  };

  const updatePercentageForFormalEducation = () => {
    if (
      currentUser.attributes.profile.publicData.formalEducation &&
      currentUser.attributes.profile.publicData.formalEducation.length > 0
    ) {
      calculatedProgress.find(v => v.step === profileSteps.formalEducation).progress = 100;
    }
  };

  const updatePercentageForCertifications = () => {
    if (
      currentUser.attributes.profile.publicData.certifications &&
      currentUser.attributes.profile.publicData.certifications.length > 0
    ) {
      calculatedProgress.find(v => v.step === profileSteps.certifications).progress = 100;
    }
  };

  const updatePercentageForSkills = () => {

    const noOfSkills = currentUser.attributes.profile.publicData.skills?.length || 0;
    var skillsPercentage = 0;
    if (noOfSkills === 1) {
      skillsPercentage = 17;
    } else if (noOfSkills === 2) {
      skillsPercentage = 33;
    } else if (noOfSkills >= 3) {
      skillsPercentage = 50;
    }

    const noOfIndustries = currentUser.attributes.profile.publicData.industries?.length || 0;
    var industriesPercentage = 0;
    if (noOfIndustries === 1) {
      industriesPercentage = 17;
    } else if (noOfIndustries === 2) {
      industriesPercentage = 33;
    } else if (noOfIndustries >= 3) {
      industriesPercentage = 50;
    }

    calculatedProgress.find(v => v.step === profileSteps.skills).progress = skillsPercentage + industriesPercentage;

    // const percentageStep = 6.66; //100% / maximum 15 skills => each skill wights 6.6%

    // calculatedProgress.find(v => v.step === profileSteps.skills).progress = Math.round(
    //   percentageStep * currentUser.attributes.profile.publicData.skills.length
    // );

  };

  const updatePercentageForLanguages = () => {
    if (
      currentUser.attributes.profile.publicData.languages &&
      currentUser.attributes.profile.publicData.languages.length > 0
    ) {
      calculatedProgress.find(v => v.step === profileSteps.languages).progress = 100;
    }
  };

  const updatePercentageForHourlyRate = () => {
    if (currentUser.attributes.profile.publicData.hourlyRate) {
      calculatedProgress.find(v => v.step === profileSteps.hourlyRate).progress = 100;
    }
  };

  const updatePercentageForClientUserProfile = () => {
    //profile details
    var percentage = 0;
    const percentageStep = 33;
    if (currentUser.attributes.profile.firstName) {
      percentage += percentageStep;
    }
    if (currentUser.attributes.profile.lastName) {
      percentage += percentageStep;
    }

    if (currentUser.attributes?.profile?.publicData?.jobTitle) {
      percentage += percentageStep + 1;
    }

    // proofOfIdentity is optional
    // if (currentUser.attributes.profile.protectedData.proofOfIdentity) {
    //   percentage += percentageStep;
    // }

    calculatedProgress.find(v => v.step === profileSteps.profileDetails).progress = Math.round(
      percentage
    );
  };

  const updatePercentageForCompanyProfile = () => {
    var percentage = 0;
    const percentageStep = 12.5;

    if (currentUser.attributes?.profile?.publicData?.companyProfile?.companyName) {
      percentage += percentageStep;
    }
    if (currentUser.attributes?.profile?.publicData?.companyProfile?.companySize) {
      percentage += percentageStep;
    }
    if (currentUser.attributes?.profile?.publicData?.companyAddress?.city) {
      percentage += percentageStep;
    }
    if (currentUser.attributes?.profile?.publicData?.companyAddress?.country) {
      percentage += percentageStep;
    }
    if (currentUser.attributes?.profile?.publicData?.companyAddress?.state) {
      percentage += percentageStep;
    }
    if (currentUser.attributes?.profile?.publicData?.companyAddress?.streetName) {
      percentage += percentageStep;
    }
    if (currentUser.attributes?.profile?.publicData?.companyAddress?.suburb) {
      percentage += percentageStep;
    }
    if (currentUser.attributes?.profile?.publicData?.companyAddress?.zipCode) {
      percentage += percentageStep;
    }
    calculatedProgress.find(v => v.step === profileSteps.companyProfile).progress = Math.round(
      percentage
    );
  };

  const updatePercentageForPaymentMethod = () => {
    if (currentUser.stripeCustomer?.defaultPaymentMethod?.attributes?.card) {
      calculatedProgress.find(v => v.step === profileSteps.paymentMethod).progress = 100;
    }
  };

  if (isConsultant) {
    updatePercentageForConsultantUserProfile();
    updatePercentageForWorkExperience();
    updatePercentageForReferences();
    updatePercentageForFormalEducation();
    updatePercentageForCertifications();
    updatePercentageForSkills();
    updatePercentageForLanguages();
    updatePercentageForHourlyRate();
    // updatePercentageForPaymentMethod();
  } else {
    updatePercentageForClientUserProfile();
    updatePercentageForCompanyProfile();
    updatePercentageForPaymentMethod();
  }

  return calculatedProgress;
  // var firstItem = calculatedProgress.filter(s => s.step === currentStepOrParent);
  // var remainingItems = calculatedProgress.filter(s => s.step !== currentStepOrParent).sort((a, b) => a.progress - b.progress);

  // return firstItem.concat(remainingItems);
};

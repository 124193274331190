export const profileSteps = {
    inputMode: "inputMode",
    profileDetails: "profileDetails",
    profileAddress: "profileAddress",
    profileIdentityDocument: "profileIdentityDocument",
    workExperience: "workExperience",
    references: "references",
    formalEducation: "formalEducation",
    certifications: "certifications",
    skills: "skills",
    languages: "languages",
    hourlyRate: "hourlyRate",
    paymentMethod: "paymentMethod",
    companyProfile: "companyProfile",
    companyAddress: "companyAddress",
    review: "review",
}
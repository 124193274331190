import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';

import css from './ProfileCompletionBanner.module.css';
import CheckedYellowIcon from './icons/CheckedYellowIcon';
import { calculatePercentages } from '../utils/profileCompletion';

const ProfileCompletionBannerComponent = props => {
  const { currentUser, isSearchPage, isContactDetailsPage } = props;

  const calculatedProgress = calculatePercentages(currentUser);

  const sumOfPercentages = calculatedProgress.reduce((acc, step) => acc + step.progress, 0);
  const averageOfAllPercentages = Math.round(sumOfPercentages / calculatedProgress.length);

  return (isSearchPage || isContactDetailsPage) ? (
    <div className={css.rootSearchPage}>
      <p className={css.titleSearchPage}>
        <FormattedMessage id={'ProfileCompletionBanner.searchPage.title'} />
        <CheckedYellowIcon />
      </p>
      <div className={css.progressBar}>
        <ProgressBar progress={averageOfAllPercentages} />
      </div>
      <span className={css.percentageSearchPage}>
        <FormattedMessage
          id={'ProfileCompletionBanner.searchPage.percentage'}
          values={{ percentage: averageOfAllPercentages }}
        />
      </span>
    </div>
  ) : (
    <div className={css.root}>
      <p className={css.title}>
        <FormattedMessage id={'ProfileCompletionBanner.title'} />
        <span className={css.percentage}>
          <FormattedMessage
            id={'ProfileCompletionBanner.percentage'}
            values={{ percentage: averageOfAllPercentages }}
          />
        </span>
      </p>
    </div>
  );
};

const ProfileCompletionBanner = compose(injectIntl)(ProfileCompletionBannerComponent);
ProfileCompletionBanner.displayName = 'ProfileCompletionBanner';

export default ProfileCompletionBanner;

import React from 'react';

const CheckedYellowIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      style={{ fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.5" r="7" fill="#FFC20C" />
      <circle cx="12" cy="12.5" r="7" fill="black" fillOpacity="0.2" />
      <path
        d="M9.61792 12.5879L11.5235 14.1541L14.382 10.8477"
        stroke="white"
        strokeWidth="0.933333"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CheckedYellowIcon;
